import { blockManifestReader } from "eddev/_internal";
import { dynamic } from "eddev/dynamic";
import core from "../../../blocks/_core";

const manifest = {
  "acf/about-about": dynamic(() => import("../../../blocks/about/about.tsx")),
  "acf/about-credits": dynamic(() => import("../../../blocks/about/credits.tsx")),
  "acf/about-donate": dynamic(() => import("../../../blocks/about/donate.tsx")),
  "acf/about-text": dynamic(() => import("../../../blocks/about/text.tsx")),
  "acf/content-section": dynamic(() => import("../../../blocks/content/section.tsx")),
  "acf/content-subpage-hero": dynamic(() => import("../../../blocks/content/subpage-hero.tsx")),
  "acf/home-course": dynamic(() => import("../../../blocks/home/course.tsx")),
  "acf/home-heading": dynamic(() => import("../../../blocks/home/heading.tsx")),
  "acf/home-hero": dynamic(() => import("../../../blocks/home/hero.tsx")),
  "acf/home-home": dynamic(() => import("../../../blocks/home/home.tsx")),
  "acf/home-image": dynamic(() => import("../../../blocks/home/image.tsx")),
  "acf/home-image-text": dynamic(() => import("../../../blocks/home/image-text.tsx")),
  "acf/home-percentage": dynamic(() => import("../../../blocks/home/percentage.tsx")),
  "acf/home-rankings": dynamic(() => import("../../../blocks/home/rankings.tsx")),
  "acf/home-rankings-item": dynamic(() => import("../../../blocks/home/rankings-item.tsx")),
  "acf/home-research": dynamic(() => import("../../../blocks/home/research.tsx")),
  "acf/home-social": dynamic(() => import("../../../blocks/home/social.tsx")),
  "acf/home-social-item": dynamic(() => import("../../../blocks/home/social-item.tsx")),
  "acf/home-stats": dynamic(() => import("../../../blocks/home/stats.tsx")),
  "acf/home-video": dynamic(() => import("../../../blocks/home/video.tsx")),
  "acf/leaderboard": dynamic(() => import("../../../blocks/leaderboard.tsx")),
  "acf/myths-vs-facts-item": dynamic(() => import("../../../blocks/myths-vs-facts/item.tsx")),
  "acf/myths-vs-facts-items": dynamic(() => import("../../../blocks/myths-vs-facts/items.tsx")),
  "acf/myths-vs-facts-myths-vs-facts": dynamic(() => import("../../../blocks/myths-vs-facts/myths-vs-facts.tsx")),
  "acf/stigma-index-stigma-index": dynamic(() => import("../../../blocks/stigma-index/stigma-index.tsx")),
  "acf/terms-terms": dynamic(() => import("../../../blocks/terms/terms.tsx")),
  "acf/terms-text": dynamic(() => import("../../../blocks/terms/text.tsx")),
  "acf/terms-texts": dynamic(() => import("../../../blocks/terms/texts.tsx")),
  ...core,
};

const info = {
  "acf/about-about": { "slug": "about/about", "tags": ["root"], "flags": {} },
  "acf/about-credits": { "slug": "about/credits", "flags": {} },
  "acf/about-donate": { "slug": "about/donate", "flags": {} },
  "acf/about-text": { "slug": "about/text", "flags": {} },
  "acf/content-section": { "slug": "content/section", "flags": {} },
  "acf/content-subpage-hero": { "slug": "content/subpage-hero", "flags": {} },
  "acf/home-course": { "slug": "home/course", "flags": {} },
  "acf/home-heading": { "slug": "home/heading", "flags": {} },
  "acf/home-hero": { "slug": "home/hero", "flags": {} },
  "acf/home-home": { "slug": "home/home", "tags": ["root"], "flags": {} },
  "acf/home-image": { "slug": "home/image", "flags": {} },
  "acf/home-image-text": { "slug": "home/image-text", "flags": {} },
  "acf/home-percentage": { "slug": "home/percentage", "flags": {} },
  "acf/home-rankings": { "slug": "home/rankings", "flags": {} },
  "acf/home-rankings-item": { "slug": "home/rankings-item", "flags": {} },
  "acf/home-research": { "slug": "home/research", "flags": {} },
  "acf/home-social": { "slug": "home/social", "flags": {} },
  "acf/home-social-item": { "slug": "home/social-item", "flags": {} },
  "acf/home-stats": { "slug": "home/stats", "flags": {} },
  "acf/home-video": { "slug": "home/video", "flags": {} },
  "acf/leaderboard": { "slug": "leaderboard", "flags": {} },
  "acf/myths-vs-facts-item": { "slug": "myths-vs-facts/item", "flags": {} },
  "acf/myths-vs-facts-items": { "slug": "myths-vs-facts/items", "flags": {} },
  "acf/myths-vs-facts-myths-vs-facts": { "slug": "myths-vs-facts/myths-vs-facts", "tags": ["root"], "flags": {} },
  "acf/stigma-index-stigma-index": { "slug": "stigma-index/stigma-index", "tags": ["root"], "flags": {} },
  "acf/terms-terms": { "slug": "terms/terms", "tags": ["root"], "flags": {} },
  "acf/terms-text": { "slug": "terms/text", "flags": {} },
  "acf/terms-texts": { "slug": "terms/texts", "flags": {} },
};

blockManifestReader.set(manifest, info);

export default manifest;

if (import.meta.hot) {
  import.meta.hot.accept();
}

import { viewManifestReader } from "eddev/_internal";
import { dynamic } from "eddev/dynamic";
const manifest = {
  "404": dynamic(() => import("../../../views/404.tsx")),
  "_app": dynamic(() => import("../../../views/_app.tsx")),
  "front-page": dynamic(() => import("../../../views/front-page.tsx")),
  "page": dynamic(() => import("../../../views/page.tsx")),
  "single": dynamic(() => import("../../../views/single.tsx")),
};

viewManifestReader.value = manifest;

export default manifest;

if (import.meta.hot) {
  import.meta.hot.accept();
}

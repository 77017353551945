import { ContentBlock } from "eddev/blocks"

const HTMLBlock = (props: ContentBlock) => {
  if (typeof props.innerHTML === "string") {
    return <div dangerouslySetInnerHTML={{ __html: props.innerHTML }} />
  } else {
    return null
  }
}

export default {
  // "core/paragraph": HTMLBlock,
  // "core/embed": (props) => {
  //   return <div>{JSON.stringify(props)}</div>
  // },
}
